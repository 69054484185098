const PROD = 'production';
const STAGING = 'staging';
const LOCAL = 'local';

const ENV =
    (process.env.GATSBY_ENV) ||
    LOCAL;

const api = {
    [PROD]: 'https://us-central1-symposeum-production.cloudfunctions.net/api',
    [STAGING]: 'https://us-central1-symposeum-staging.cloudfunctions.net/api',
    [LOCAL]: 'http://localhost:5000/symposeum-staging/us-central1/api'
}[ENV];

const stripe = {
    [PROD]: 'pk_live_51HGr9xFeu41vUQSWmar96ZRvtwXMdkBhshmT580wWyHiMjH4bro5gHCZ7dZi6JhTOSTNtIxx4pAncE0CpPVFsYrQ009eLvgYSN',
    [STAGING]: 'pk_test_51HGr9xFeu41vUQSWcq4Ju5iveUNhsRRXRj32sNGv61Ccv2IgfJnq3dcXtKDeFFP93PXgGchvDfy1XkigDKGq1EtU00u7ppLyHL',
    [LOCAL]: 'pk_test_51HGr9xFeu41vUQSWcq4Ju5iveUNhsRRXRj32sNGv61Ccv2IgfJnq3dcXtKDeFFP93PXgGchvDfy1XkigDKGq1EtU00u7ppLyHL'
}[ENV];

const url = {
    [PROD]: 'https://symposeum.us',
    [STAGING]: 'https://staging.symposeum.us',
    [LOCAL]: 'http://localhost:8000'
}[ENV];

module.exports = {
    key: '88b4180bccda04e281a2167961',
    ghost: 'https://api.symposeum.us/ghost/api/v3/content',
    stripe,
    api,
    url,
    ENV,
    PROD
}