import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import moment from "moment";
import symposeumMobile from "../../images/symposeumMobile.png";

import { Navigation } from ".";
import config from "../../utils/siteConfig";

// Styles
import "../../styles/app.css";

const DomParser = require("dom-parser");
const parser = new DomParser();
import stripHtml from "string-strip-html";

const reg = new RegExp(
    /<!--kg-card-end: html-->(.+)<!--kg-card-begin: html-->/
);
const link = new RegExp(/src="(.+?)"/);

const getACF = (footer) => {
    const dom = parser.parseFromString(footer.node.html);
    const contactUs =
        dom.getElementById("contact-us").innerHTML.match(reg)[1] || "";
    const theDial =
        dom.getElementById("the-dial").innerHTML.match(reg)[1] || "";
    const donate = dom.getElementById("donate").innerHTML.match(reg)[1] || "";

    return {
        contactUs: stripHtml(contactUs).result,
        theDial: stripHtml(theDial).result,
        donate: stripHtml(donate).result,
    };
};

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({
    data,
    children,
    bodyClass,
    isHome,
    isIssue,
    flex,
    bg,
}) => {
    const site = data.allGhostSettings.edges[0].node;
    const footer = data.allGhostPage.edges[0];
    const tags = data.allGhostTag;
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;
    const { contactUs, theDial, donate } = getACF(footer);

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={`${bodyClass} body`} />
            </Helmet>

            <div className="viewport">
                <div
                    className="viewport-top"
                    style={{
                        display: flex ? "flex" : "",
                        flex: flex ? "1" : "",
                        flexDirection: flex ? "column" : "",
                    }}
                >
                    {/* The main header section on top of the screen */}
                    <header>
                        <Navigation
                            data={site.navigation}
                            tags={tags}
                            navClass="site-nav-item"
                        />
                    </header>

                    <main
                        style={{
                            display: flex ? "flex" : "",
                            flex: flex ? "1" : "",
                            flexDirection: flex ? "column" : "",
                            backgroundColor: bg ? bg : "",
                        }}
                        className={`site-main ${
                            !isIssue ? "site-main-margin" : ""
                        }`}
                    >
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>
                </div>

                {/* <div className="viewport-bottom"> */}
                {/* The footer at the very bottom of the screen */}
                <footer
                    className="site-foot"
                    style={{ zIndex: 1, backgroundColor: "white" }}
                >
                    <div className="flex justify-between items-center sm:justify-center text-2xl unselected-filter font-sans tracking-widest">
                        <div className="ml-6 sm:ml-0">
                            <img
                                id="symp-logo"
                                src={symposeumMobile}
                                className="h-24"
                            />
                        </div>
                        <div className="flex flex-col ml-6 sm:ml-12 mr-6">
                            <div>
                                A project of{" "}
                                <a
                                    className="unselected-filter underline"
                                    href={theDial}
                                >
                                    The Dial
                                </a>
                            </div>
                            <div className="unselected-filter font-sans tracking-widest">
                                © {moment(Date.now()).format("YYYY")} Symposeum
                                Magazine
                            </div>
                        </div>
                        <div className="hidden sm:flex">
                            <div
                                className="pl-6 pr-6"
                                style={{ borderLeft: "2px solid #e6e6e6" }}
                            >
                                <a
                                    className="unselected-filter underline"
                                    href={`mailto:${contactUs}`}
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            {/* </div> */}
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostPage(filter: { slug: { eq: "footer" } }) {
                    edges {
                        node {
                            html
                            feature_image
                        }
                    }
                }
                allGhostTag {
                    nodes {
                        slug
                        description
                        name
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
