const DomParser = require('dom-parser');
const parser = new DomParser();
import stripHtml from "string-strip-html";

const reg = new RegExp(/<!--kg-card-end: html-->(.+)<!--kg-card-begin: html-->/);
const regInclusive = new RegExp(/<!--kg-card-end: html-->.+?<!--kg-card-begin: html-->/);
const link = new RegExp(/src="(.+?)"/);

export const getACF = (item, id) => {
    try {
        const dom = parser.parseFromString(item.html);
        const f = dom.getElementById(id).innerHTML.match(reg)[1] || '';
        return stripHtml(f).result;
    } catch (err) {
        return '';
    }
}

export const getHTMLBlock = (item, id) => {
    try {
        const dom = parser.parseFromString(item.html);
        const f = dom.getElementById(id).innerHTML.match(reg) || '';
        return f;
    } catch (err) {
        return '';
    }
}

export const removeHTMLBlock = (item, i) => {
    try {
        const dom = parser.parseFromString(item.html);
        const f = dom.getElementById(id);
        f.remove();
        return dom.innerHTML;
    } catch (err) {
        return '';
    }
}

export const removeComments = (html) => {
    return html.replace(/(\/\*[\w\'\s\r\n\*]*\*\/)/g, '');
}

export const getNPTH = (post) => post?.tags?.find(t => t?.slug?.includes('npth'));

export const getMiscTag = (post) => post?.tags?.find(t => !t?.slug?.includes('npth') && !t?.slug?.includes('issue') && !t?.slug?.includes('Issue') && !t?.name?.includes('#') && !t?.slug?.includes('home'));

export const getIssue = (post) => post?.tags?.find(t => t?.slug?.includes('issue') || t?.slug?.includes('Issue'));

export const getOrder = (post) => {
    const tag = post.tags.find((t) => {
        try {
            const parsed = parseInt(t.name.replace('#', ''));
            const isOrder = t.visibility === 'internal' && (!isNaN(parsed));
            if (isOrder) {
                return parsed;
            }
            return null;
        } catch(err) {
            return null;
        }
    });
    return tag ? parseInt(tag.name.replace('#', '')) : false;
}